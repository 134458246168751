import React, { useState, useEffect } from 'react';
import './../../styles/components/Solutions.css';
import CurrentTheme from './../CurrentTheme';
import config from '../../config';

export default function SolutionsFR() {
  const [promoCode, setPromoCode] = useState('');
  const specialPromoCodes = ['ETE50', 'GTE25', 'RUGBY75', 'BSC50'];

  useEffect(() => {
    if (window) {
      const queryParams = new URLSearchParams(window.location.search);
      const paramName = 'promo';

      if (queryParams.has(paramName)) {
        const paramValue = queryParams.get(paramName);
        specialPromoCodes.includes(paramValue)
          ? setPromoCode(paramValue)
          : setPromoCode('');
      }
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="solutions">
        <div className="bens-container">
          <div className="solutions-top">
            <div className="box-col">
              <div className="media-group">
                <a
                  className="media-item"
                  href="https://bens-digital-change.com/changez-votre-salaire"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {promoCode === 'GTE25' ||
                  promoCode === 'RUGBY75' ||
                  promoCode === 'BSC50' ? (
                    <>
                      <h4>
                        {'Changez votre salaire'}
                        <br />
                        {'de votre compte CHF'}
                        <br />
                        {'à votre compte EUR'}
                      </h4>
                    </>
                  ) : (
                    <h4>{'Changez votre salaire'}</h4>
                  )}
                  <img
                    src="/img/sol-01.webp"
                    alt="Sol 01"
                    loading="lazy"
                    width={340}
                    height={341}
                  />
                </a>

                <a
                  className="media-item"
                  href="https://bens-digital-change.com/transferts-de-capital-vieillesse"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4>Transferts de capital vieillesse Epargne</h4>
                  <img
                    src="/img/sol-02.webp"
                    alt="Sol 02"
                    loading="lazy"
                    width={340}
                    height={351}
                  />
                </a>
              </div>

              {promoCode === 'GTE25' ||
              promoCode === 'RUGBY75' ||
              promoCode === 'BSC50' ? (
                <div
                  className="media-group"
                  style={{}}
                >
                  <a
                    style={{
                      alignSelf: 'center',
                    }}
                    className="media-item"
                    href="https://bens-digital-change.com/fr/employeur"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h4>
                      {'Changez votre salaire'}
                      <br />
                      {'sans compte bancaire'}
                      <br />
                      {'suisse'}
                    </h4>
                    <img
                      src="/img/s-bank.webp"
                      style={{
                        height: '15rem',
                        width: '15rem',
                        objectFit: 'cover',
                      }}
                      alt="Sol 02"
                      loading="lazy"
                      width={1216}
                      height={684}
                    />
                  </a>
                </div>
              ) : null}

              <div className="media-group">
                <a
                  className="media-item"
                  href="https://bens-digital-change.com/transactions-immobilieres"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4>Transactions immobilières à l’étranger </h4>
                  <img
                    src="/img/sol-03.webp"
                    alt="Sol 03"
                    loading="lazy"
                    width={358}
                    height={351}
                  />
                </a>

                <a
                  className="media-item"
                  href="https://bens-digital-change.com/achat-et-vente-a-linternational"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4>Achat / Vente à l’International</h4>
                  <img
                    src="/img/sol-04.webp"
                    alt="Sol 04"
                    loading="lazy"
                    width={351}
                    height={363}
                  />
                </a>
              </div>
            </div>
            <div className="box-col solutions-top-right">
              <h2>Notre solution de change numérique s’ajuste à vos besoins</h2>
              <a
                href="https://bens-digital-change.swiss/register"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-green"
              >
                Inscription Gratuite et sans engagement
              </a>
              <a
                href="https://calendly.com/bens_digital/demande-d-assistance"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contacter nos conseillers
              </a>
            </div>
          </div>
        </div>
      </div>

      {specialPromoCodes.includes(promoCode) ? (
        <div className="flex flex-column">
          <div className="rewards flex flex-column mx-auto flex-fill">
            <div className="bens-container flex">
              <div className="flex flex-column justify-content-center mx-auto">
                <h2 className="bens-section-title">
                  {
                    'Cumulez des BDP et économisez sur vos prochaines transactions'
                  }
                </h2>
                <p className="fs-4 text-center">
                  {'Utilisez vos BDP pour augmenter votre pouvoir d’achat'}
                </p>
                <p className="fs-4 text-center">
                  {
                    'Atteignez le Statut Gold pour bénéficier d’avantage encore plus important'
                  }
                </p>
                <p className="fs-1 fw-bold text-center is-red">
                  {'1 BDP = 1 CHF'}
                </p>
              </div>

              <div className="flex flex-column justify-content-center mx-auto mt-4">
                <h3 className="bens-section-title">
                  {'Comment obtenir des Ben S. Digital Points ? (BDP)'}
                </h3>
                <div className="bens-circle-container">
                  <div className="bens-circle">{'BDP De bienvenue'}</div>
                  <div className="bens-circle">{'Anniversaire'}</div>
                </div>
                <div className="bens-circle-container">
                  <div className="bens-circle">{'Parrainage'}</div>
                </div>
                <div className="bens-circle-container">
                  <div className="bens-circle">{'Avis Vérifiés'}</div>
                  <div className="bens-circle">{'Volume de Transaction'}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="bens-support flex flex-column mx-auto flex-fill">
            <div className="bens-container flex">
              <div className="bs-box">
                <div className="bs-box-media">
                  <img
                    className="hero-bdp-icon"
                    src="https://bens-digital-change.com/images/v2/rewards/woman.jpg"
                    alt="Manon"
                    loading="lazy"
                  />
                </div>
                <div className="bs-box-content">
                  <span>
                    {'Manon bénéficie de'}
                    <span className="is-red">{'285 CHF'}</span>
                    {
                      'en plus des meilleurs taux grâce à Ben S. Digital Rewards !'
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="rewards flex flex-column mx-auto flex-fill">
            <div className="bens-container flex">
              <div className="bens-container bens-cta-container">
                <a
                  href="https://bens-digital-change.com/rewards"
                  target="_blank"
                  className="cta-button cta-button-primary"
                  rel="noreferrer noopener"
                >
                  {'OBTENIR MES BDP DE BIENVENUE '}
                </a>
                <a
                  href="https://bens-digital-change.com/rewards"
                  target="_blank"
                  className="cta-button cta-button-secondary"
                  rel="noreferrer noopener"
                >
                  {'En savoir plus '}
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="solutions">
        <div className="bens-container">
          <div className="section-08-box solutions-bottom">
            <div className="box-col">
              <img
                src="https://bens-digital-change.com/images/v2/get-mobile-back.webp"
                alt="Get Mobile"
                className="box-img"
                loading="lazy"
                width={733}
                height={671}
              />
            </div>
            <div className="box-col">
              <h2 className="box-title">
                Notre solution est disponible sur mobile
              </h2>
              <img
                src="/img/bens-title-dark.svg"
                alt="Bens"
                className="box-desc-img"
                loading="lazy"
                width={399}
                height={51}
              />
              <p className="box-long-desc">
                Réalisez l’ensemble de vos opérations de change sur notre
                application disponible sur Android comme sur IOS. Vous souhaitez
                changer votre devise à un moment précis ?
              </p>
              <p className="box-long-desc">
                Notre application vous offre la possibilité de créer une alerte
                afin de vous prévenir quand le taux de change atteint celui que
                vous désirez.
              </p>
              <a
                href="https://bens-digital-change.com/download-mobile-app"
                className="box-store d-flex justify-content-center align-items-center flex-row gap-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://bens-digital-change.com/images/v2/qr-code.png"
                  alt="Bens Store"
                  loading="lazy"
                  width={112}
                  height={112}
                />
                <div className="d-flex flex-column justify-content-center align-items-center gap-1">
                  <img
                    data-tag="footer-download-ios"
                    src="https://bens-digital-change.com/images/v2/ios-store-fr.svg"
                    alt="IOS"
                    width={120}
                    height={40}
                    loading="lazy"
                  />
                  <img
                    data-tag="footer-download-android"
                    src="https://bens-digital-change.com/images/v2/store-custom-android-fr.svg"
                    alt="Android"
                    width={135}
                    height={40}
                    loading="lazy"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          className="solutions-back"
          style={{
            background:
              'linear-gradient(180deg, ' +
              CurrentTheme(
                specialPromoCodes.includes(promoCode) ? 'summer' : config.theme
              )['color'][0] +
              ' 0%, ' +
              CurrentTheme(
                specialPromoCodes.includes(promoCode) ? 'summer' : config.theme
              )['color'][1] +
              ' 100%)',
          }}
        ></div>
      </div>
    </>
  );
}
