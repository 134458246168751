import React, { useState, useEffect } from 'react';
import './../../styles/utils/Hero.css';
import Calculator from './../../components/Calculator';
import TrustpilotWidget from '../../components/TrustpilotWidget';
import CurrentTheme from '../../components/CurrentTheme';
import config from '../../config';
import YouTube from 'react-youtube';
import { IconLoader } from '@tabler/icons-react';
import { StatsFr } from './../../components/Stats/fr';
import promoCodeList from '../../data/promoCodeList';

export default function HeroFR() {
  const [visible, setVisible] = useState(false);
  const [promoCode, setPromoCode] = useState('JANV24');
  // eslint-disable-next-line
  const [promoCodes, setPromoCodes] = useState(promoCodeList);
  const [wait, setWait] = useState(true);
  const specialPromoCodes = ['ETE50', 'GTE25', 'RUGBY75', 'BSC50'];

  const handleBtnOpen = () => {
    setVisible(true);
  };

  const handleBtnClose = () => {
    setVisible(false);
  };

  const handleWrapper = () => {
    setVisible(false);
  };

  const onPlayerReady = (event) => {
    event.target.playVideo();
  };
  const destroyVideo = (event) => {
    event.target.destroyVideo();
  };

  useEffect(() => {
    if (window) {
      const queryParams = new URLSearchParams(window.location.search);
      const paramName = 'promo';

      if (queryParams.has(paramName)) {
        const paramValue = queryParams.get(paramName);
        promoCodes.some((p) => p.code === paramValue)
          ? setPromoCode(paramValue)
          : setPromoCode('JANV24');
      }

      setWait(false);
    }
    // eslint-disable-next-line
  }, []);

  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };

  return (
    <div id="bens-hero">
      <div className="bens-container">
        {config.promotion ? (
          <div className="hero-col md-reverse">
            <div className="hero-col-box is-bigger">
              <div className="hero-col-box-content">
                {promoCodes.find((p) => p.code === promoCode)?.code ===
                'CAS125' ? (
                  <img
                    src="/img/Pasino_chFR+casino_du_lac_Meyrin_Black.webp"
                    alt="Casino du Lac"
                    height={64}
                    loading="lazy"
                    style={{ marginBottom: '1rem' }}
                  />
                ) : null}
                <h1
                  className="hero-content-text"
                  dangerouslySetInnerHTML={createMarkup(
                    promoCodes.find((p) => p.code === promoCode)?.value
                  )}
                />
              </div>
              <div className="hero-action">
                <a
                  href="https://bens-digital-change.swiss/register"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {'PROFITER DE L’OFFRE DE BIENVENUE'}
                </a>
              </div>
            </div>
            <div className="hero-col-box is-small has-promotion">
              <div className="is-promotion">
                <h6>{promoCodes.find((p) => p.code === promoCode)?.date}</h6>
                {promoCode === 'ETE50' ? (
                  <p className="is-bigger">
                    {
                      '50 CHF offerts sur votre 1ère transaction d’un montant  ≥ 3000 CHF'
                    }
                  </p>
                ) : null}
                {promoCode === 'GTE25' ? (
                  <p className="is-bigger">
                    {
                      '25 CHF offerts sur votre 1ère opération d’un montant ≥ 1000 CHF '
                    }
                  </p>
                ) : null}
                {promoCode === 'RUGBY75' ? (
                  <p className="is-bigger">
                    {
                      '75 CHF offerts sur votre 1ère opération d’un montant ≥ 1000 CHF'
                    }
                  </p>
                ) : null}
                {promoCode === 'BSC50' ? (
                  <p className="is-bigger">
                    {
                      '50 CHF offerts sur votre 1ère transaction d’un montant ≥ 1000 CHF'
                    }
                  </p>
                ) : null}
                <p className="is-bigger">{'avec le code promo'}</p>
                <span>{wait ? <IconLoader /> : promoCode}</span>
                {/* <div className="hero-action">
                  <a
                    href="https://bens-digital-change.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {"Je profite de l’offre"}
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="hero-col">
            <div className="hero-col-box has-not-promotion">
              <h1>
                {
                  'Changez facilement vos Francs Suisses en Euros Grâce à notre plateforme en ligne à un taux très attractif'
                }
              </h1>
            </div>
          </div>
        )}

        <div className="hero-col">
          {config.theme === 'default' ? (
            <div className="hero-col-box">
              <div
                className="has-cover-filter"
                style={{
                  background:
                    'linear-gradient(135deg, ' +
                    CurrentTheme(
                      specialPromoCodes.includes(promoCode)
                        ? 'summer'
                        : config.theme
                    )['color'][0] +
                    ' 0%, ' +
                    CurrentTheme(
                      specialPromoCodes.includes(promoCode)
                        ? 'summer'
                        : config.theme
                    )['color'][1] +
                    ' 100%)',
                }}
              ></div>
              <div className="hero-box-container">
                <div className="hero-row">
                  <h2 className="hero-title">
                    {
                      'Le partenaire de change préféré des frontaliers, garantissant des économies optimales.'
                    }
                  </h2>
                  <div className="hero-video">
                    <h3 className="video-title">
                      {'Comment fonctionne notre service :'}
                    </h3>
                    <div
                      id="video-action"
                      onClick={handleBtnOpen}
                    >
                      <img
                        src={'/img/video-cover.jpg?' + config.version}
                        alt="Ben S. Digital Change"
                        className="caption-img"
                        width={331}
                        height={185}
                        loading="lazy"
                      />
                    </div>
                    {visible ? (
                      <div
                        id="video-wrapper"
                        onClick={handleWrapper}
                        className={visible ? 'is-show' : ''}
                      >
                        <YouTube
                          id="iframe"
                          videoId="D_RcUWSm10g"
                          opts={{
                            playsinline: 0,
                            autoplay: 1,
                            origin: window.location.origin,
                          }}
                          onReady={onPlayerReady}
                          onEnd={destroyVideo}
                        />
                        <div
                          id="video-close-btn"
                          onClick={handleBtnClose}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                          </svg>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="hero-partner">
                    <h5 className="partner-title">
                      {'La Solution multicanale avec'}
                    </h5>
                    <img
                      className="partner-img"
                      src="https://bens-digital-change.com/images/logo/bens_shop_change.svg"
                      alt="Ben S. Shop Change"
                      width={100}
                      height={67}
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="hero-row">
                  <img
                    className="hero-bens-img"
                    src="https://bens-digital-change.com/images/v2/char_bens.webp"
                    alt="The Ben S."
                    width={245}
                    height={556}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="hero-box-container">
                <div className="hero-row">
                  <StatsFr />
                </div>
              </div>
            </div>
          ) : (
            <div
              className="hero-col-box has-cover"
              style={{
                backgroundImage:
                  'url(' +
                  CurrentTheme(
                    specialPromoCodes.includes(promoCode)
                      ? 'summer'
                      : config.theme
                  )['cover'] +
                  ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div
                className="has-cover-filter"
                style={{
                  background:
                    'linear-gradient(135deg, ' +
                    CurrentTheme(
                      specialPromoCodes.includes(promoCode)
                        ? 'summer'
                        : config.theme
                    )['color'][0] +
                    ' 0%, ' +
                    CurrentTheme(
                      specialPromoCodes.includes(promoCode)
                        ? 'summer'
                        : config.theme
                    )['color'][1] +
                    ' 100%)',
                }}
              ></div>

              <div className="hero-box-container">
                <div className="hero-row">
                  <img
                    src={
                      CurrentTheme(
                        specialPromoCodes.includes(promoCode)
                          ? 'summer'
                          : config.theme
                      )['cover']
                    }
                    alt="Cover"
                    className="hero-media"
                    loading="lazy"
                    width={1170}
                    height={629}
                  />
                </div>
              </div>

              <div className="hero-action">
                <a
                  href="https://bens-digital-change.swiss/register"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {'PROFITER DE L’OFFRE DE BIENVENUE'}
                </a>
              </div>
            </div>
          )}

          <div className="hero-col-box is-small has-calculator">
            <Calculator></Calculator>
            <TrustpilotWidget></TrustpilotWidget>
            <a
              href="https://bens-digital-change.swiss/register"
              target="_blank"
              rel="noopener noreferrer"
              className="hero-action-btn"
            >
              {promoCode === 'ETE50'
                ? 'OBTENIR LES 50 CHF OFFERTS'
                : promoCode === 'GTE25'
                ? 'OBTENIR LES 25 CHF OFFERTS'
                : promoCode === 'RUGBY75'
                ? 'Obtenir les 75 CHF Offerts'
                : 'Commencer maintenant'}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
